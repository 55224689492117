.badge-list {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4rem;
}

@media only screen  and (max-width: 1400px) {
    .badge-list{
        flex-wrap: wrap;
    }
}