.url_detail {
}
.url-title__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
}
.url-title__sub-container {
  display: flex;
  align-items: center;
}

.url-title__container--pink {
  background: #fff1f1;
}
.url-title__container--green {
  background: #ecfefe;
}
.url-title__container--yellow {
  background: #fffbf0;
}
.sub-panel {
  background-color: white;
  padding: 2rem;
}
.accordion {
  border-radius: 20px;
  overflow: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.accordion__title {
  font-size: 1.3rem;
  color: #1f3366;
  font-weight: 600;
  margin-left: 0.8rem;
  text-align: left;
  overflow-wrap: anywhere;
}
.accordion__title-sub {
  font-size: 1rem;
}
.accordion__count {
  width: 2.4rem;
  min-width: 2.4rem;
  height: 2.4rem;
  min-height: 2.4rem;
  border-radius: 50%;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordion__count--green {
  background: #27736D;
}
.accordion__count--pink {
  background: #f97a7d;
}
.accordion__count--yellow {
  background: #fac515;
}
.accordion__count--sub {
  background: #1f3366;
}
.accordion__icon-container {
  display: flex;
  align-items: center;
}
.scan_title {
  margin-right: 1rem;
  margin-left: 1rem;
  color: #54a0ba;
  font-weight: 700;
}

.accordion__error-heading {
  font-size: 0.9rem;
  color: #1f3366;
  font-weight: 700;
}
.accordion__error-paragraph {
  font-size: 0.9rem;
  color: #1f3366;
  margin-bottom: 1.5rem;
}

.accordion__error-detail {
  width: 32%;
}
.accordion__error-detail-container {
  display: flex;
  justify-content: space-between;
  border-top: 1px dashed #c5c6d3;
  padding-top: 1rem;
}
.accordion__error-detail-subcontainer {
  width: 65%;
  overflow: auto;
  overflow-x: hidden;
}
.info__box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.info__title {
  font-size: 0.9rem;
  margin-right: 0.4rem;
  font-weight: bold;
  color: #54a0ba;
}

.accordion__button-container {
  position:sticky;
  background: #ececf9;
  border-radius: 50px;
  width: fit-content;
  margin: 1rem 0;
  margin-left: auto;
  top: 0;
}
.accordion__button {
  border-radius: 50px;
  font-size: 0.7rem;
  padding: 0.2rem 1rem;
  font-weight: bold;
  color: #9497a9;
  background: #ececf9;
}

.accordion__button--selected {
  color: #54a0ba;
  background: #1f3366;
}
.accordion__error-detail-subcontainer-image {
  height: 330px;
}

.accordion_congratulation {
  border-radius: 10px;
  padding: 1.5rem 4rem;
  background: #f0f0f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.congratulation__heading {
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #1f3366;
}
.congratulation__sub-heading {
  font-weight: normal;
  font-size: 1rem;
  color: #1f3366;
}
.congratulation__container {
  margin-left: 4rem;
}

.progress_bar{
  width:100% !important;
}

@media screen and (max-width: 760px){
  .chakra-button{
    margin: 0!important;
  }
}