.metrics__container {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}
.compliance-score__container {
  width: 70%;
  padding: 1.4rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.compliance-score__container--current {
  width: 50%;
  display: flex;
  padding-right: 1rem;
  border-right: 1px dashed #9497a9;
}
.compliance-score__detail {
  width: 50%;
}
.compliance-subcontainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 0;
  border-bottom: 1px dashed #9497a9;
}

.compliance-subcontainer--progress {
  padding-top: 1rem;
  padding-bottom: 0;
  border: none;
}
.score-heading {
  color: #1f3366;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.2;
}
.risk {
  font-weight: bold;
  font-size: 1rem;
}
.risk--yellow {
  color: #e5971d;
}
.risk--red {
  color: #990000;
}
.risk--green {
  color: #0e850a;
}

.percentage-container {
  width: 50%;
  margin-top: 2rem;
}
.percentage-subcontainer {
  width: 35%;
}

.current-percent {
  /* color: #e5971d; */
  font-size: 3rem;
}

.compliance-subcontainer > .percentage-container {
  margin-top: 0rem;
}
.compliance-subcontainer > .current-percent {
  font-size: 1rem;
}
.initial-compliance {
  width: 48%;
  padding-left: 0.5rem;
}
.initial-percent {
  font-size: 2rem;
  /* color: #ff0000; */
}

.compliance-percent {
  font-size: 2rem;
  color: #27736D;
}
.metrics__heading {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: #43454f;
}
.progress-bar__light-blue {
  background-color: lightblue;
}
.progress-bg {
  background-color: #000;
}
.errors-container {
  display: flex;
  width: 28%;
  flex-direction: column;
  padding: 1.4rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  justify-content: space-evenly;
}

.error-subcontainer {
  display: flex;
  margin: 1rem 0;
}
.error_percent {
  font-size: 3rem;
  font-weight: bold;
  margin-left: 1.5rem;
}
.error_percent--no-error {
  color: #27736D;
}
.error_percent--error {
  color: #f5767c;
}
.progress-bar {
  height: 0.5rem;
}
.errors-progress {
  border-bottom: 1px dashed #9497a9;
  padding-bottom: 2rem;
}
@media screen and (max-width: 700px) {
  .metrics__container {
    flex-wrap: wrap;
  }
  .compliance-score__container {
    width: 100%;
    flex-wrap: wrap;
  }
  .compliance-score__container--current {
    width: 100%;
    border: none;
    padding-bottom: 1rem;
    justify-content: space-between;
    padding-right: 0rem;
    border-bottom: 1px dashed #9497a9;
    margin-bottom: 1rem;
    
  } 
  .compliance-score__detail {
    width: 100%;
  }
  .compliance-subcontainer{
    width: 100%;
  }
  .initial-compliance{
    width: 100%;
  }
  .percentage-container{
   margin-top: 0; 
   width: 35%;
  }
  .current-percent{
    font-size: 2rem;
  }
  .errors-container {
    width: 100%;
  }
}
@media screen  and (max-width: 1000px) {
  .metrics__container {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    flex-wrap: wrap;
    margin: auto;

}
.compliance-score__container {
  width: 100%;
  
}
.errors-container {width: 100%;}
}