code[class*=language-],
pre[class*=language-] {
    white-space: pre-wrap;
    word-break: break-word;
}

.err-instance-heading {
    text-align: left;
    margin: 0;
    padding: 0;
    line-height: 10px;
    margin-top: 20px;
    font-weight: bold;
    font-size: 0.9rem;
    color: #1f3366;
    font-weight: 700;
}
.err-instance-heading .err-instance-wrapper {
    display: flex;
    margin: 1rem 0 0 0;
}
.err-instance-heading .err-instance-wrapper .css-1uiwwan, .err-instance-heading .err-instance-wrapper  span.instance {
    display: flex;
    margin: 0 1.5rem 0 0;
}

.err-instance-wrapper .chakra-checkbox span{
    width: 22px;
    height: 22px;
    border-color: #C2C2C2;
}