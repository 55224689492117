.badge {
  cursor: pointer;
  width: 33%;
  display: inline-block;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  padding: 1rem;
  border-top-width: 0.7rem;
  border-top-style: solid;
  border-radius: 10px;
  mix-blend-mode: normal;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  height: 130px;
  background: white;
}

.badge--pink {
  border-color: #f5767c;
}
.badge--green {
  border-color: #27736D;
}
.badge--yellow {
  border-color: #fac515;
}
.badge--gray {
  border-color: #808080;
}
.badge--pink-selected {
  background: #fff5f5;
}
.badge--green-selected {
  background: #ecfffe;
}
.badge--yellow-selected {
  background: #fffbf0;
}
.badge--gray-selected {
  background: #c8c8c8;
}
.badge__count {
  font-size: 3.5rem;
  font-weight: 800;
  color: #1f3366;
  position: center !important;
  bottom: 0rem !important;
  right: 0rem !important;
}
.badge__color--pink {
  color: #ff8080;
}
.badge__color--green {
  color: #27736D;
}
.badge__color--yellow {
  color: #fac515;
}

.badge__heading {
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700rem;
  color: #1f3366;
  width: 100%;
  margin-top: .5rem !important;
}
.badge__detail {
  position: absolute !important;
  width: 100% !important;
  top: .5rem !important;
  left: .5rem !important;

}
.badge__icon{
padding-right: .5rem !important;
}
@media screen and (max-width: 1570px) {
  .badge{

    width: 80% !important;
    position: relative;
    margin: auto;
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 1570px) {
  .badge{
    width: 100%;
    margin-bottom: 1rem;
  }
}

.badge.selected p {
  text-decoration: underline;
}