.complianceCard{
    width: 48%;
}
.complianceCardTitle{
    font-size: 35px;
    padding-bottom: 17px;
    padding-left: 17px;
    padding-top: 17px;
}
.charts{
    height: 200px;
}
.exportButton{
 margin-left: 20px;
}
.align{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}.urlSelection{
    display: flex;
}
.content{
    margin:0 auto;
    min-height: 100vh;
    min-width: 80%;
}
.openDrawer {
    padding-left: 200px;
}
@media screen and (max-width: 760px) {
    .openDrawer {
        padding-left: 0;
    }
    .complianceCard{
        width: 90%;
        margin-bottom: 20px;
        padding: 10px;
    }
    .align{
        display: flex;
        justify-content: space-evenly;

    }    
    .align>input{
        width:100%;

    }
    .exportButton{
        display: none;
    }
    .scanButton{
        margin: 0 auto;
        
    }
    .charts g[aria-label="Legend"]{
        
    }
    .mainContent{
        padding-left: 200px;
        min-height: 100vh;
    }
  }