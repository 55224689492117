.individual_scan_input{
    font-size:20 !important;
    width:50% !important;
  }
  .scan_complete_alert{
    margin-bottom: 10px;
    width: 97%;
  }
  @media screen and (min-width: 760px) {
    .individual_scan_input{
        font-size:20 !important;
        width:20rem !important;
      }
}

.max-width-30 {
  max-width: 30rem;
}