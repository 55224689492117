.nav {
  width: 140px;
  height: 50vh;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 8rem;
  margin-left: 2rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  width: 15%;
  margin-right: 1rem;
  list-style: none;
}
.nav__logo {
  display: flex;
  justify-content: center;
  padding: 1.8rem;
  margin: auto;
}
.nav__link{
  width: 100%;
}
.dashboard{
  display: flex;
  flex-wrap: wrap;

}
.nav__option-container {
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #f0f0f6;
  width: 100%;
  height: 100%;
}
.nav__option-container--selected {
  background: #007599;
}
.nav__option-label {
  font-size: 0.8rem;
  font-weight: bold;
}
.nav__option-label--selected {
  color: #a6f2ff;
}
@media screen and (max-width: 1000px) {
  .nav{
    width: 80% !important;
    position: relative;
    margin: auto;
    margin-bottom: 5rem;
    top: unset;
    height: auto;
  }
  .nav__logo{
    margin: auto;
  }
}