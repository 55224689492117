@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #2199df;
  text-decoration: unset;
}

ul {
  margin-left: 1.5rem;
  list-style: none;
}

button,
input[type="submit"] {
  border: none;
  background: none;
  color: unset;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
  margin-top: 1em;
  margin-bottom: 1em;
}

/* general */

body {
  font-family: "Roboto", sans-serif;
}

.page {
  margin-top: 5%;
  padding: 1rem;
}

.container {
  margin: 0 auto;
  max-width: 900px;
}

/* header */

#header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #251f5d;
}
#header .title {
  margin: 0;
  padding: 0 1rem;
  color: #ffffff;
}
#header .auth-options {
  height: 100%;
  display: flex;
}
#header .auth-options button {
  padding: 0 1rem;
  background-color: #251f5d;
  color: #ffffff;
  font-size: 1.1rem;
}
#header .auth-options button:hover {
  background-color: #201f63;
}

/* form */

.form label,
.form input {
  display: block;
}
.form input {
  margin-bottom: 1rem;
}
.form label {
  margin-bottom: 0.2rem;
}
.form input[type="text"],
.form input[type="email"],
.form input[type="password"] {
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  background-color: #f0f0f0;
  font-size: 1.1rem;
}
.form input:focus {
  border: 1px solid #50a4e9;
  box-shadow: inset 0px 0px 5px rgba(38, 142, 228, 0.5);
  outline: none;
}
.form input[type="submit"] {
  padding: 0.3rem 0.5rem;
  border: 1px solid #6aa051;
  border-radius: 8px;
  background-color: #c8ecb7;
  font-size: 1.1rem;
}

/* todo list */

.todo-list ul {
  list-style: disc;
  cursor: pointer;
}

.todo-list li:hover {
  text-decoration: line-through;
}

/* error notice */

.error-notice {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #e07c7c;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d6d6;
}
.error-notice button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #df4343;
  color: #ffffff;
}

/* media queries */

@media screen and (max-width: 550px) {
  #header .title {
    font-size: 1.2rem;
  }
  #header .auth-options button {
    padding: 0 0.5rem;
    font-size: 0.9rem;
  }
}

/* reset */
html {
    background-color: #181a1b !important;
}
html, body, input, textarea, select, button {
    background-color: #181a1b;
}
html, body, input, textarea, select, button {
    border-color: #746c5f;
    color: #eae8e5;
}
a {
    color: #3492ff;
}
table {
    border-color: #555c5f;
}
::placeholder {
    color: #b4ada3;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: #565c00 !important;
    color: #eae8e5 !important;
}
::selection {
    background-color: #004eac !important;
    color: #eae8e5 !important;
}
::-moz-selection {
    background-color: #004eac !important;
    color: #eae8e5 !important;
}

/* Text Style */
*:not(pre) {
  font-family: Segoe UI !important;
}

/* Invert Style */
.jfk-bubble.gtx-bubble, .captcheck_answer_label > input + img {
    filter: invert(100%) hue-rotate(180deg) brightness(101%) contrast(90%) !important;
}

/* Variables Style */
:root {
   --darkreader-neutral-background: #131516;
   --darkreader-neutral-text: #dad6d1;
   --darkreader-selection-background: #004eac;
   --darkreader-selection-text: #eae8e5;
}

/* Modified CSS */

.resize-triggers > div {
    background-image: initial;
    background-color: rgb(34, 36, 38);
}
a {
    color: rgb(56, 165, 228); text-decoration-color: unset;
}
ul {
    list-style-image: initial;
}
button, input[type="submit"] {
    border-color: initial;
    background-image: none;
    background-color: initial;
    color: unset;
}
#header {
    background-color: rgb(30, 25, 75);
}
#header .title {
    color: rgb(234, 232, 229);
}
#header .auth-options button {
    background-color: rgb(30, 25, 75);
    color: rgb(234, 232, 229);
}
#header .auth-options button:hover {
    background-color: rgb(30, 25, 80);
}
.form input[type="text"], .form input[type="email"], .form input[type="password"] {
    border-color: rgb(66, 72, 74);
    background-color: rgb(32, 35, 37);
}
.form input:focus {
    border-color: rgb(18, 88, 144);
    box-shadow: rgba(22, 107, 178, 0.5) 0px 0px 5px inset; outline-color: initial;
}
.form input[type="submit"] {
    border-color: rgb(81, 122, 62);
    background-color: rgb(53, 85, 22);
}
.todo-list ul {
    list-style-image: initial;
}
.todo-list li:hover {
    text-decoration-color: initial;
}
.error-notice {
    border-color: rgb(123, 29, 29);
    background-color: rgb(69, 12, 12);
}
.error-notice button {
    background-color: rgb(158, 27, 27);
    color: rgb(234, 232, 229);
}
html {
    -webkit-tap-highlight-color: transparent;
}
body {
    color: rgb(211, 207, 201);
    background-color: rgb(24, 26, 27);
}
[tabindex="-1"]:focus:not(:focus-visible) {
    outline-color: initial !important;
}
abbr[data-original-title],
abbr[title] {
    text-decoration-color: initial;
    border-bottom-color: initial;
}
a {
    color: rgb(52, 164, 255);
    text-decoration-color: initial;
    background-color: transparent;
}
a:hover {
    color: rgb(0, 118, 214);
    text-decoration-color: initial;
}
a:not([href]):not([class]) {
    color: inherit;
    text-decoration-color: initial;
}
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration-color: initial;
}
caption {
    color: rgb(160, 152, 138);
}
button:focus {
    outline-color: rgb(188, 124, 0);
}
fieldset {
    border-color: initial;
}
legend {
    color: inherit;
}
hr {
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-top-color: rgba(141, 131, 116, 0.1);
}
.mark,
mark {
    background-color: rgb(64, 55, 7);
}
.list-unstyled {
    list-style-image: initial;
}
.list-inline {
    list-style-image: initial;
}
.blockquote-footer {
    color: rgb(160, 152, 138);
}
.img-thumbnail {
    background-color: rgb(24, 26, 27);
    border-color: rgb(57, 62, 64);
}
.figure-caption {
    color: rgb(160, 152, 138);
}
code {
    color: rgb(235, 75, 148);
}
a > code {
    color: inherit;
}
kbd {
    color: rgb(234, 232, 229);
    background-color: rgb(28, 30, 31);
}
pre {
    color: rgb(211, 207, 201);
}
pre code {
    color: inherit;
}
.table {
    color: rgb(211, 207, 201);
}
.table td,
.table th {
    border-top-color: rgb(57, 62, 64);
}
.table thead th {
    border-bottom-color: rgb(57, 62, 64);
}
.table tbody + tbody {
    border-top-color: rgb(57, 62, 64);
}
.table-bordered {
    border-color: rgb(57, 62, 64);
}
.table-bordered td,
.table-bordered th {
    border-color: rgb(57, 62, 64);
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border-color: initial;
}
.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
    color: rgb(211, 207, 201);
    background-color: rgba(0, 0, 0, 0.07);
}
.table-primary,
.table-primary > td,
.table-primary > th {
    background-color: rgb(44, 48, 51);
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: rgb(0, 69, 143);
}
.table-hover .table-primary:hover {
    background-color: rgb(52, 56, 58);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: rgb(52, 56, 58);
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
    background-color: rgb(46, 51, 52);
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: rgb(69, 75, 78);
}
.table-hover .table-secondary:hover {
    background-color: rgb(54, 59, 61);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: rgb(54, 59, 61);
}
.table-success,
.table-success > td,
.table-success > th {
    background-color: rgb(30, 73, 48);
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: rgb(43, 107, 59);
}
.table-hover .table-success:hover {
    background-color: rgb(34, 84, 55);
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: rgb(34, 84, 55);
}
.table-info,
.table-info > td,
.table-info > th {
    background-color: rgb(24, 72, 79);
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: rgb(35, 105, 115);
}
.table-hover .table-info:hover {
    background-color: rgb(28, 82, 90);
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: rgb(28, 82, 90);
}
.table-warning,
.table-warning > td,
.table-warning > th {
    background-color: rgb(93, 71, 0);
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: rgb(142, 107, 0);
}
.table-hover .table-warning:hover {
    background-color: rgb(108, 82, 0);
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: rgb(108, 82, 0);
}
.table-danger,
.table-danger > td,
.table-danger > th {
    background-color: rgb(79, 14, 20);
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: rgb(120, 20, 29);
}
.table-hover .table-danger:hover {
    background-color: rgb(92, 16, 24);
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: rgb(92, 16, 24);
}
.table-light,
.table-light > td,
.table-light > th {
    background-color: rgb(25, 27, 28);
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: rgb(49, 54, 56);
}
.table-hover .table-light:hover {
    background-color: rgb(32, 35, 36);
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: rgb(32, 35, 36);
}
.table-dark,
.table-dark > td,
.table-dark > th {
    background-color: rgb(56, 61, 63);
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: rgb(78, 84, 88);
}
.table-hover .table-dark:hover {
    background-color: rgb(63, 68, 71);
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: rgb(63, 68, 71);
}
.table-active,
.table-active > td,
.table-active > th {
    background-color: rgba(0, 0, 0, 0.07);
}
.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.07);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.07);
}
.table .thead-dark th {
    color: rgb(234, 232, 229);
    background-color: rgb(44, 47, 49);
    border-color: rgb(116, 107, 95);
}
.table .thead-light th {
    color: rgb(183, 177, 168);
    background-color: rgb(35, 38, 39);
    border-color: rgb(57, 62, 64);
}
.table-dark {
    color: rgb(234, 232, 229);
    background-color: rgb(44, 47, 49);
}
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: rgb(116, 107, 95);
}
.table-dark.table-bordered {
    border-color: initial;
}
.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(24, 26, 27, 0.05);
}
.table-dark.table-hover tbody tr:hover {
    color: rgb(234, 232, 229);
    background-color: rgba(24, 26, 27, 0.07);
}
@media (max-width: 575.98px) {
    .table-responsive-sm > .table-bordered {
        border-color: initial;
    }
}
@media (max-width: 767.98px) {
    .table-responsive-md > .table-bordered {
        border-color: initial;
    }
}
@media (max-width: 991.98px) {
    .table-responsive-lg > .table-bordered {
        border-color: initial;
    }
}
@media (max-width: 1199.98px) {
    .table-responsive-xl > .table-bordered {
        border-color: initial;
    }
}
.table-responsive > .table-bordered {
    border-color: initial;
}
.form-control {
    color: rgb(183, 177, 168);
    background-color: rgb(24, 26, 27);
    border-color: rgb(61, 66, 69);
}
.form-control:focus {
    color: rgb(183, 177, 168);
    background-color: rgb(24, 26, 27);
    border-color: rgb(0, 68, 141);
    outline-color: initial;
    box-shadow: rgba(0, 99, 206, 0.25) 0px 0px 0px 0.2rem;
}
.form-control::placeholder {
    color: rgb(160, 152, 138);
}
.form-control:disabled,
.form-control[readonly] {
    background-color: rgb(35, 38, 39);
}
.form-control-plaintext {
    color: rgb(211, 207, 201);
    background-color: transparent;
    border-color: transparent;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
    color: rgb(160, 152, 138);
}
.valid-feedback {
    color: rgb(98, 219, 125);
}
.valid-tooltip {
    color: rgb(234, 232, 229);
    background-color: rgba(32, 135, 56, 0.9);
}
.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: rgb(37, 158, 65);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: rgb(37, 158, 65);
    box-shadow: rgba(32, 135, 56, 0.25) 0px 0px 0px 0.2rem;
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: rgb(37, 158, 65);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNCIgaGVpZ2h0PSI1Ij48ZGVmcz48ZmlsdGVyIGlkPSJkYXJrcmVhZGVyLWltYWdlLWZpbHRlciI+PGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAuMjUxIC0wLjYyMCAtMC42NzggMC4wMDAgMS4wNDUgLTAuNjUyIDAuMjkxIC0wLjY3MCAwLjAwMCAxLjAzMSAtMC42NDIgLTAuNjE1IDAuMjUzIDAuMDAwIDEuMDA0IDAuMDAwIDAuMDAwIDAuMDAwIDEuMDAwIDAuMDAwIiAvPjwvZmlsdGVyPjwvZGVmcz48aW1hZ2Ugd2lkdGg9IjQiIGhlaWdodD0iNSIgZmlsdGVyPSJ1cmwoI2RhcmtyZWFkZXItaW1hZ2UtZmlsdGVyKSIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9zdmcreG1sLCUzY3N2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0JyBoZWlnaHQ9JzUnIHZpZXdCb3g9JzAgMCA0IDUnJTNlJTNjcGF0aCBmaWxsPSclMjMzNDNhNDAnIGQ9J00yIDBMMCAyaDR6bTAgNUwwIDNoNHonLyUzZSUzYy9zdmclM2UiIC8+PC9zdmc+"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-color: rgb(24, 26, 27);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: rgb(37, 158, 65);
    box-shadow: rgba(32, 135, 56, 0.25) 0px 0px 0px 0.2rem;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
    color: rgb(98, 219, 125);
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
    color: rgb(98, 219, 125);
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
    border-color: rgb(37, 158, 65);
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    border-color: rgb(35, 144, 60);
    background-color: rgb(39, 165, 92);
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: rgba(32, 135, 56, 0.25) 0px 0px 0px 0.2rem;
}
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: rgb(37, 158, 65);
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: rgb(37, 158, 65);
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: rgb(37, 158, 65);
    box-shadow: rgba(32, 135, 56, 0.25) 0px 0px 0px 0.2rem;
}
.invalid-feedback {
    color: rgb(225, 71, 86);
}
.invalid-tooltip {
    color: rgb(234, 232, 229);
    background-color: rgba(167, 29, 42, 0.9);
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: rgb(149, 26, 37);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiPjxkZWZzPjxmaWx0ZXIgaWQ9ImRhcmtyZWFkZXItaW1hZ2UtZmlsdGVyIj48ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMC4yNTEgLTAuNjIwIC0wLjY3OCAwLjAwMCAxLjA0NSAtMC42NTIgMC4yOTEgLTAuNjcwIDAuMDAwIDEuMDMxIC0wLjY0MiAtMC42MTUgMC4yNTMgMC4wMDAgMS4wMDQgMC4wMDAgMC4wMDAgMC4wMDAgMS4wMDAgMC4wMDAiIC8+PC9maWx0ZXI+PC9kZWZzPjxpbWFnZSB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbHRlcj0idXJsKCNkYXJrcmVhZGVyLWltYWdlLWZpbHRlcikiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2Uvc3ZnK3htbCwlM2NzdmcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB3aWR0aD0nMTInIGhlaWdodD0nMTInIGZpbGw9J25vbmUnIHN0cm9rZT0nJTIzZGMzNTQ1JyB2aWV3Qm94PScwIDAgMTIgMTInJTNlJTNjY2lyY2xlIGN4PSc2JyBjeT0nNicgcj0nNC41Jy8lM2UlM2NwYXRoIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIGQ9J001LjggMy42aC40TDYgNi41eicvJTNlJTNjY2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nJTIzZGMzNTQ1JyBzdHJva2U9J25vbmUnLyUzZSUzYy9zdmclM2UiIC8+PC9zdmc+");
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: rgb(149, 26, 37);
    box-shadow: rgba(167, 29, 42, 0.25) 0px 0px 0px 0.2rem;
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: rgb(149, 26, 37);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNCIgaGVpZ2h0PSI1Ij48ZGVmcz48ZmlsdGVyIGlkPSJkYXJrcmVhZGVyLWltYWdlLWZpbHRlciI+PGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAuMjUxIC0wLjYyMCAtMC42NzggMC4wMDAgMS4wNDUgLTAuNjUyIDAuMjkxIC0wLjY3MCAwLjAwMCAxLjAzMSAtMC42NDIgLTAuNjE1IDAuMjUzIDAuMDAwIDEuMDA0IDAuMDAwIDAuMDAwIDAuMDAwIDEuMDAwIDAuMDAwIiAvPjwvZmlsdGVyPjwvZGVmcz48aW1hZ2Ugd2lkdGg9IjQiIGhlaWdodD0iNSIgZmlsdGVyPSJ1cmwoI2RhcmtyZWFkZXItaW1hZ2UtZmlsdGVyKSIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9zdmcreG1sLCUzY3N2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0JyBoZWlnaHQ9JzUnIHZpZXdCb3g9JzAgMCA0IDUnJTNlJTNjcGF0aCBmaWxsPSclMjMzNDNhNDAnIGQ9J00yIDBMMCAyaDR6bTAgNUwwIDNoNHonLyUzZSUzYy9zdmclM2UiIC8+PC9zdmc+"),
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiPjxkZWZzPjxmaWx0ZXIgaWQ9ImRhcmtyZWFkZXItaW1hZ2UtZmlsdGVyIj48ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMC4yNTEgLTAuNjIwIC0wLjY3OCAwLjAwMCAxLjA0NSAtMC42NTIgMC4yOTEgLTAuNjcwIDAuMDAwIDEuMDMxIC0wLjY0MiAtMC42MTUgMC4yNTMgMC4wMDAgMS4wMDQgMC4wMDAgMC4wMDAgMC4wMDAgMS4wMDAgMC4wMDAiIC8+PC9maWx0ZXI+PC9kZWZzPjxpbWFnZSB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbHRlcj0idXJsKCNkYXJrcmVhZGVyLWltYWdlLWZpbHRlcikiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2Uvc3ZnK3htbCwlM2NzdmcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB3aWR0aD0nMTInIGhlaWdodD0nMTInIGZpbGw9J25vbmUnIHN0cm9rZT0nJTIzZGMzNTQ1JyB2aWV3Qm94PScwIDAgMTIgMTInJTNlJTNjY2lyY2xlIGN4PSc2JyBjeT0nNicgcj0nNC41Jy8lM2UlM2NwYXRoIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIGQ9J001LjggMy42aC40TDYgNi41eicvJTNlJTNjY2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nJTIzZGMzNTQ1JyBzdHJva2U9J25vbmUnLyUzZSUzYy9zdmclM2UiIC8+PC9zdmc+");
    background-color: rgb(24, 26, 27);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: rgb(149, 26, 37);
    box-shadow: rgba(167, 29, 42, 0.25) 0px 0px 0px 0.2rem;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: rgb(225, 71, 86);
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: rgb(225, 71, 86);
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: rgb(149, 26, 37);
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
    border-color: rgb(136, 23, 34);
    background-color: rgb(140, 24, 35);
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
    box-shadow: rgba(167, 29, 42, 0.25) 0px 0px 0px 0.2rem;
}
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: rgb(149, 26, 37);
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: rgb(149, 26, 37);
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: rgb(149, 26, 37);
    box-shadow: rgba(167, 29, 42, 0.25) 0px 0px 0px 0.2rem;
}
.btn {
    color: rgb(211, 207, 201);
    background-color: transparent;
    border-color: transparent;
}
.btn:hover {
    color: rgb(211, 207, 201);
    text-decoration-color: initial;
}
.btn.focus,
.btn:focus {
    outline-color: initial;
    box-shadow: rgba(0, 99, 206, 0.25) 0px 0px 0px 0.2rem;
}
.btn-primary {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
    border-color: rgb(0, 87, 181);
}
.btn-primary:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 85, 176);
    border-color: rgb(0, 94, 196);
}
.btn-primary.focus,
.btn-primary:focus {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 85, 176);
    border-color: rgb(0, 94, 196);
    box-shadow: rgba(0, 89, 183, 0.5) 0px 0px 0px 0.2rem;
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
    border-color: rgb(0, 87, 181);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 79, 165);
    border-color: rgb(0, 96, 200);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: rgba(0, 89, 183, 0.5) 0px 0px 0px 0.2rem;
}
.btn-secondary {
    color: rgb(234, 232, 229);
    background-color: rgb(89, 96, 100);
    border-color: rgb(103, 95, 84);
}
.btn-secondary:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(74, 80, 83);
    border-color: rgb(111, 103, 91);
}
.btn-secondary.focus,
.btn-secondary:focus {
    color: rgb(234, 232, 229);
    background-color: rgb(74, 80, 83);
    border-color: rgb(111, 103, 91);
    box-shadow: rgba(91, 99, 103, 0.5) 0px 0px 0px 0.2rem;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
    color: rgb(234, 232, 229);
    background-color: rgb(89, 96, 100);
    border-color: rgb(103, 95, 84);
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(70, 75, 78);
    border-color: rgb(113, 105, 93);
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: rgba(91, 99, 103, 0.5) 0px 0px 0px 0.2rem;
}
.btn-success {
    color: rgb(234, 232, 229);
    background-color: rgb(32, 135, 56);
    border-color: rgb(37, 158, 65);
}
.btn-success:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(26, 110, 45);
    border-color: rgb(40, 170, 70);
}
.btn-success.focus,
.btn-success:focus {
    color: rgb(234, 232, 229);
    background-color: rgb(26, 110, 45);
    border-color: rgb(40, 170, 70);
    box-shadow: rgba(59, 145, 79, 0.5) 0px 0px 0px 0.2rem;
}
.btn-success.disabled,
.btn-success:disabled {
    color: rgb(234, 232, 229);
    background-color: rgb(32, 135, 56);
    border-color: rgb(37, 158, 65);
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(24, 102, 42);
    border-color: rgb(41, 173, 72);
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: rgba(59, 145, 79, 0.5) 0px 0px 0px 0.2rem;
}
.btn-info {
    color: rgb(234, 232, 229);
    background-color: rgb(18, 131, 148);
    border-color: rgb(21, 153, 173);
}
.btn-info:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(15, 107, 121);
    border-color: rgb(23, 165, 188);
}
.btn-info.focus,
.btn-info:focus {
    color: rgb(234, 232, 229);
    background-color: rgb(15, 107, 121);
    border-color: rgb(23, 165, 188);
    box-shadow: rgba(46, 142, 158, 0.5) 0px 0px 0px 0.2rem;
}
.btn-info.disabled,
.btn-info:disabled {
    color: rgb(234, 232, 229);
    background-color: rgb(18, 131, 148);
    border-color: rgb(21, 153, 173);
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(14, 99, 112);
    border-color: rgb(24, 168, 190);
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: rgba(46, 142, 158, 0.5) 0px 0px 0px 0.2rem;
}
.btn-warning {
    color: rgb(211, 207, 201);
    background-color: rgb(202, 152, 0);
    border-color: rgb(178, 133, 0);
}
.btn-warning:hover {
    color: rgb(211, 207, 201);
    background-color: rgb(181, 135, 0);
    border-color: rgb(194, 145, 0);
}
.btn-warning.focus,
.btn-warning:focus {
    color: rgb(211, 207, 201);
    background-color: rgb(181, 135, 0);
    border-color: rgb(194, 145, 0);
    box-shadow: rgba(180, 137, 10, 0.5) 0px 0px 0px 0.2rem;
}
.btn-warning.disabled,
.btn-warning:disabled {
    color: rgb(211, 207, 201);
    background-color: rgb(202, 152, 0);
    border-color: rgb(178, 133, 0);
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
    color: rgb(211, 207, 201);
    background-color: rgb(171, 127, 0);
    border-color: rgb(198, 148, 0);
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: rgba(180, 137, 10, 0.5) 0px 0px 0px 0.2rem;
}
.btn-danger {
    color: rgb(234, 232, 229);
    background-color: rgb(167, 29, 42);
    border-color: rgb(149, 26, 37);
}
.btn-danger:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(162, 28, 41);
    border-color: rgb(162, 28, 41);
}
.btn-danger.focus,
.btn-danger:focus {
    color: rgb(234, 232, 229);
    background-color: rgb(162, 28, 41);
    border-color: rgb(162, 28, 41);
    box-shadow: rgba(148, 26, 38, 0.5) 0px 0px 0px 0.2rem;
}
.btn-danger.disabled,
.btn-danger:disabled {
    color: rgb(234, 232, 229);
    background-color: rgb(167, 29, 42);
    border-color: rgb(149, 26, 37);
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(153, 26, 38);
    border-color: rgb(166, 29, 41);
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: rgba(148, 26, 38, 0.5) 0px 0px 0px 0.2rem;
}
.btn-light {
    color: rgb(211, 207, 201);
    background-color: rgb(27, 30, 31);
    border-color: rgb(51, 55, 57);
}
.btn-light:hover {
    color: rgb(211, 207, 201);
    background-color: rgb(38, 41, 43);
    border-color: rgb(58, 63, 65);
}
.btn-light.focus,
.btn-light:focus {
    color: rgb(211, 207, 201);
    background-color: rgb(38, 41, 43);
    border-color: rgb(58, 63, 65);
    box-shadow: rgba(45, 49, 52, 0.5) 0px 0px 0px 0.2rem;
}
.btn-light.disabled,
.btn-light:disabled {
    color: rgb(211, 207, 201);
    background-color: rgb(27, 30, 31);
    border-color: rgb(51, 55, 57);
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
    color: rgb(211, 207, 201);
    background-color: rgb(42, 45, 47);
    border-color: rgb(60, 65, 67);
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: rgba(45, 49, 52, 0.5) 0px 0px 0px 0.2rem;
}
.btn-dark {
    color: rgb(234, 232, 229);
    background-color: rgb(44, 47, 49);
    border-color: rgb(122, 113, 100);
}
.btn-dark:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(29, 32, 33);
    border-color: rgb(130, 121, 107);
}
.btn-dark.focus,
.btn-dark:focus {
    color: rgb(234, 232, 229);
    background-color: rgb(29, 32, 33);
    border-color: rgb(130, 121, 107);
    box-shadow: rgba(67, 72, 75, 0.5) 0px 0px 0px 0.2rem;
}
.btn-dark.disabled,
.btn-dark:disabled {
    color: rgb(234, 232, 229);
    background-color: rgb(44, 47, 49);
    border-color: rgb(122, 113, 100);
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(24, 27, 28);
    border-color: rgb(132, 123, 109);
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: rgba(67, 72, 75, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-primary {
    color: rgb(52, 164, 255);
    border-color: rgb(0, 87, 181);
}
.btn-outline-primary:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
    border-color: rgb(0, 87, 181);
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: rgba(0, 99, 206, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: rgb(52, 164, 255);
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
    border-color: rgb(0, 87, 181);
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: rgba(0, 99, 206, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-secondary {
    color: rgb(160, 152, 138);
    border-color: rgb(103, 95, 84);
}
.btn-outline-secondary:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(89, 96, 100);
    border-color: rgb(103, 95, 84);
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: rgba(89, 96, 100, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: rgb(160, 152, 138);
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(89, 96, 100);
    border-color: rgb(103, 95, 84);
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: rgba(89, 96, 100, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-success {
    color: rgb(98, 219, 125);
    border-color: rgb(37, 158, 65);
}
.btn-outline-success:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(32, 135, 56);
    border-color: rgb(37, 158, 65);
}
.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: rgba(32, 135, 56, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: rgb(98, 219, 125);
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(32, 135, 56);
    border-color: rgb(37, 158, 65);
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: rgba(32, 135, 56, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-info {
    color: rgb(82, 214, 235);
    border-color: rgb(21, 153, 173);
}
.btn-outline-info:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(18, 131, 148);
    border-color: rgb(21, 153, 173);
}
.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: rgba(18, 131, 148, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: rgb(82, 214, 235);
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(18, 131, 148);
    border-color: rgb(21, 153, 173);
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: rgba(18, 131, 148, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-warning {
    color: rgb(255, 201, 30);
    border-color: rgb(178, 133, 0);
}
.btn-outline-warning:hover {
    color: rgb(211, 207, 201);
    background-color: rgb(202, 152, 0);
    border-color: rgb(178, 133, 0);
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: rgba(202, 152, 0, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: rgb(255, 201, 30);
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
    color: rgb(211, 207, 201);
    background-color: rgb(202, 152, 0);
    border-color: rgb(178, 133, 0);
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: rgba(202, 152, 0, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-danger {
    color: rgb(225, 71, 86);
    border-color: rgb(149, 26, 37);
}
.btn-outline-danger:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(167, 29, 42);
    border-color: rgb(149, 26, 37);
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: rgba(167, 29, 42, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: rgb(225, 71, 86);
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(167, 29, 42);
    border-color: rgb(149, 26, 37);
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: rgba(167, 29, 42, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-light {
    color: rgb(230, 228, 224);
    border-color: rgb(51, 55, 57);
}
.btn-outline-light:hover {
    color: rgb(211, 207, 201);
    background-color: rgb(27, 30, 31);
    border-color: rgb(51, 55, 57);
}
.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: rgba(27, 30, 31, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: rgb(230, 228, 224);
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
    color: rgb(211, 207, 201);
    background-color: rgb(27, 30, 31);
    border-color: rgb(51, 55, 57);
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: rgba(27, 30, 31, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-dark {
    color: rgb(197, 192, 184);
    border-color: rgb(122, 113, 100);
}
.btn-outline-dark:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(44, 47, 49);
    border-color: rgb(122, 113, 100);
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: rgba(44, 47, 49, 0.5) 0px 0px 0px 0.2rem;
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: rgb(197, 192, 184);
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
    color: rgb(234, 232, 229);
    background-color: rgb(44, 47, 49);
    border-color: rgb(122, 113, 100);
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: rgba(44, 47, 49, 0.5) 0px 0px 0px 0.2rem;
}
.btn-link {
    color: rgb(52, 164, 255);
    text-decoration-color: initial;
}
.btn-link:hover {
    color: rgb(0, 118, 214);
    text-decoration-color: initial;
}
.btn-link.focus,
.btn-link:focus {
    text-decoration-color: initial;
}
.btn-link.disabled,
.btn-link:disabled {
    color: rgb(160, 152, 138);
}
.dropdown-toggle::after {
    border-top-color: initial;
    border-right-color: transparent;
    border-bottom-color: initial;
    border-left-color: transparent;
}
.dropdown-menu {
    color: rgb(211, 207, 201);
    list-style-image: initial;
    background-color: rgb(24, 26, 27);
    border-color: rgba(141, 131, 116, 0.15);
}
.dropup .dropdown-toggle::after {
    border-top-color: initial;
    border-right-color: transparent;
    border-bottom-color: initial;
    border-left-color: transparent;
}
.dropright .dropdown-toggle::after {
    border-top-color: transparent;
    border-right-color: initial;
    border-bottom-color: transparent;
    border-left-color: initial;
}
.dropleft .dropdown-toggle::before {
    border-top-color: transparent;
    border-right-color: initial;
    border-bottom-color: transparent;
}
.dropdown-divider {
    border-top-color: rgb(54, 59, 61);
}
.dropdown-item {
    color: rgb(211, 207, 201);
    background-color: transparent;
    border-color: initial;
}
.dropdown-item:focus,
.dropdown-item:hover {
    color: rgb(219, 215, 210);
    text-decoration-color: initial;
    background-color: rgb(27, 30, 31);
}
.dropdown-item.active,
.dropdown-item:active {
    color: rgb(234, 232, 229);
    text-decoration-color: initial;
    background-color: rgb(0, 99, 206);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: rgb(160, 152, 138);
    background-color: transparent;
}
.dropdown-header {
    color: rgb(160, 152, 138);
}
.dropdown-item-text {
    color: rgb(211, 207, 201);
}
.input-group-text {
    color: rgb(183, 177, 168);
    background-color: rgb(35, 38, 39);
    border-color: rgb(61, 66, 69);
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: rgb(234, 232, 229);
    border-color: rgb(0, 87, 181);
    background-color: rgb(0, 99, 206);
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: rgba(0, 99, 206, 0.25) 0px 0px 0px 0.2rem;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: rgb(0, 68, 141);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: rgb(234, 232, 229);
    background-color: rgb(45, 49, 52);
    border-color: rgb(0, 60, 126);
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
    color: rgb(160, 152, 138);
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: rgb(35, 38, 39);
}
.custom-control-label::before {
    background-color: rgb(24, 26, 27);
    border-color: rgb(70, 76, 79);
}
.custom-control-label::after {
    background-image: initial;
    background-color: initial;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: rgb(0, 87, 181);
    background-color: rgb(0, 99, 206);
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 99, 206, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 99, 206, 0.5);
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 99, 206, 0.5);
}
.custom-switch .custom-control-label::after {
    background-color: rgb(67, 72, 75);
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: rgb(24, 26, 27);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 99, 206, 0.5);
}
.custom-select {
    color: rgb(183, 177, 168);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNCIgaGVpZ2h0PSI1Ij48ZGVmcz48ZmlsdGVyIGlkPSJkYXJrcmVhZGVyLWltYWdlLWZpbHRlciI+PGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAuMjUxIC0wLjYyMCAtMC42NzggMC4wMDAgMS4wNDUgLTAuNjUyIDAuMjkxIC0wLjY3MCAwLjAwMCAxLjAzMSAtMC42NDIgLTAuNjE1IDAuMjUzIDAuMDAwIDEuMDA0IDAuMDAwIDAuMDAwIDAuMDAwIDEuMDAwIDAuMDAwIiAvPjwvZmlsdGVyPjwvZGVmcz48aW1hZ2Ugd2lkdGg9IjQiIGhlaWdodD0iNSIgZmlsdGVyPSJ1cmwoI2RhcmtyZWFkZXItaW1hZ2UtZmlsdGVyKSIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9zdmcreG1sLCUzY3N2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0JyBoZWlnaHQ9JzUnIHZpZXdCb3g9JzAgMCA0IDUnJTNlJTNjcGF0aCBmaWxsPSclMjMzNDNhNDAnIGQ9J00yIDBMMCAyaDR6bTAgNUwwIDNoNHonLyUzZSUzYy9zdmclM2UiIC8+PC9zdmc+");
    background-color: rgb(24, 26, 27);
    border-color: rgb(61, 66, 69);
}
.custom-select:focus {
    border-color: rgb(0, 68, 141);
    outline-color: initial;
    box-shadow: rgba(0, 99, 206, 0.25) 0px 0px 0px 0.2rem;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    background-image: none;
}
.custom-select:disabled {
    color: rgb(160, 152, 138);
    background-color: rgb(35, 38, 39);
}
.custom-file-input:focus ~ .custom-file-label {
    border-color: rgb(0, 68, 141);
    box-shadow: rgba(0, 99, 206, 0.25) 0px 0px 0px 0.2rem;
}
.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
    background-color: rgb(35, 38, 39);
}
.custom-file-label {
    color: rgb(183, 177, 168);
    background-color: rgb(24, 26, 27);
    border-color: rgb(61, 66, 69);
}
.custom-file-label::after {
    color: rgb(183, 177, 168);
    background-color: rgb(35, 38, 39);
    border-left-color: inherit;
}
.custom-range {
    background-color: transparent;
}
.custom-range:focus {
    outline-color: initial;
}
.custom-range:focus::-webkit-slider-thumb {
    box-shadow: rgb(24, 26, 27) 0px 0px 0px 1px,
    rgba(0, 99, 206, 0.25) 0px 0px 0px 0.2rem;
}
.custom-range::-webkit-slider-thumb {
    background-color: rgb(0, 99, 206);
    border-color: initial;
}
.custom-range::-webkit-slider-thumb:active {
    background-color: rgb(45, 49, 52);
}
.custom-range::-webkit-slider-runnable-track {
    color: transparent;
    background-color: rgb(40, 44, 45);
    border-color: transparent;
}
.custom-range:disabled::-webkit-slider-thumb {
    background-color: rgb(67, 72, 75);
}
.nav {
    list-style-image: initial;
}
.nav-link:focus,
.nav-link:hover {
    text-decoration-color: initial;
}
.nav-link.disabled {
    color: rgb(160, 152, 138);
}
.nav-tabs {
    border-bottom-color: rgb(57, 62, 64);
}
.nav-tabs .nav-link {
    border-color: transparent;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: rgb(54, 59, 61) rgb(54, 59, 61) rgb(57, 62, 64);
}
.nav-tabs .nav-link.disabled {
    color: rgb(160, 152, 138);
    background-color: transparent;
    border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: rgb(183, 177, 168);
    background-color: rgb(24, 26, 27);
    border-color: rgb(57, 62, 64) rgb(57, 62, 64) rgb(48, 53, 55);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
}
.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration-color: initial;
}
.navbar-nav {
    list-style-image: initial;
}
.navbar-toggler {
    background-color: transparent;
    border-color: transparent;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration-color: initial;
}
.navbar-toggler-icon {
    background-image: initial;
    background-color: initial;
}
.navbar-light .navbar-brand {
    color: rgba(234, 232, 229, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(234, 232, 229, 0.9);
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(234, 232, 229, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(234, 232, 229, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(234, 232, 229, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
    color: rgba(234, 232, 229, 0.9);
}
.navbar-light .navbar-toggler {
    color: rgba(234, 232, 229, 0.5);
    border-color: rgba(141, 131, 116, 0.1);
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiPjxkZWZzPjxmaWx0ZXIgaWQ9ImRhcmtyZWFkZXItaW1hZ2UtZmlsdGVyIj48ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMC4yNTEgLTAuNjIwIC0wLjY3OCAwLjAwMCAxLjA0NSAtMC42NTIgMC4yOTEgLTAuNjcwIDAuMDAwIDEuMDMxIC0wLjY0MiAtMC42MTUgMC4yNTMgMC4wMDAgMS4wMDQgMC4wMDAgMC4wMDAgMC4wMDAgMS4wMDAgMC4wMDAiIC8+PC9maWx0ZXI+PC9kZWZzPjxpbWFnZSB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbHRlcj0idXJsKCNkYXJrcmVhZGVyLWltYWdlLWZpbHRlcikiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2Uvc3ZnK3htbCwlM2NzdmcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB3aWR0aD0nMzAnIGhlaWdodD0nMzAnIHZpZXdCb3g9JzAgMCAzMCAzMCclM2UlM2NwYXRoIHN0cm9rZT0ncmdiYSUyODAsIDAsIDAsIDAuNSUyOScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIHN0cm9rZS13aWR0aD0nMicgZD0nTTQgN2gyMk00IDE1aDIyTTQgMjNoMjInLyUzZSUzYy9zdmclM2UiIC8+PC9zdmc+");
}
.navbar-light .navbar-text {
    color: rgba(234, 232, 229, 0.5);
}
.navbar-light .navbar-text a {
    color: rgba(234, 232, 229, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(234, 232, 229, 0.9);
}
.navbar-dark .navbar-brand {
    color: rgb(234, 232, 229);
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: rgb(234, 232, 229);
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(234, 232, 229, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(234, 232, 229, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(234, 232, 229, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
    color: rgb(234, 232, 229);
}
.navbar-dark .navbar-toggler {
    color: rgba(234, 232, 229, 0.5);
    border-color: rgba(48, 53, 55, 0.1);
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
    color: rgba(234, 232, 229, 0.5);
}
.navbar-dark .navbar-text a {
    color: rgb(234, 232, 229);
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: rgb(234, 232, 229);
}
.card {
    background-color: rgb(24, 26, 27);
    border-color: rgba(141, 131, 116, 0.13);
}
.card > .list-group {
    border-top-color: inherit;
    border-bottom-color: inherit;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
    border-top-color: initial;
}
.card-link:hover {
    text-decoration-color: initial;
}
.card-header {
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom-color: rgba(141, 131, 116, 0.13);
}
.card-footer {
    background-color: rgba(0, 0, 0, 0.03);
    border-top-color: rgba(141, 131, 116, 0.13);
}
.card-header-tabs {
    border-bottom-color: initial;
}
@media (min-width: 576px) {
    .card-group > .card + .card {
        border-left-color: initial;
    }
}
.accordion > .card:not(:last-of-type) {
    border-bottom-color: initial;
}
.breadcrumb {
    list-style-image: initial;
    background-color: rgb(35, 38, 39);
}
.breadcrumb-item + .breadcrumb-item::before {
    color: rgb(160, 152, 138);
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration-color: initial;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration-color: initial;
}
.breadcrumb-item.active {
    color: rgb(160, 152, 138);
}
.pagination {
    list-style-image: initial;
}
.page-link {
    color: rgb(52, 164, 255);
    background-color: rgb(24, 26, 27);
    border-color: rgb(57, 62, 64);
}
.page-link:hover {
    color: rgb(0, 118, 214);
    text-decoration-color: initial;
    background-color: rgb(35, 38, 39);
    border-color: rgb(57, 62, 64);
}
.page-link:focus {
    outline-color: initial;
    box-shadow: rgba(0, 99, 206, 0.25) 0px 0px 0px 0.2rem;
}
.page-item.active .page-link {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
    border-color: rgb(0, 87, 181);
}
.page-item.disabled .page-link {
    color: rgb(160, 152, 138);
    background-color: rgb(24, 26, 27);
    border-color: rgb(57, 62, 64);
}
a.badge:focus,
a.badge:hover {
    text-decoration-color: initial;
}
.badge-primary {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
}
a.badge-primary:focus,
a.badge-primary:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 79, 165);
}
a.badge-primary.focus,
a.badge-primary:focus {
    outline-color: initial;
    box-shadow: rgba(0, 99, 206, 0.5) 0px 0px 0px 0.2rem;
}
.badge-secondary {
    color: rgb(234, 232, 229);
    background-color: rgb(89, 96, 100);
}
a.badge-secondary:focus,
a.badge-secondary:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(70, 75, 78);
}
a.badge-secondary.focus,
a.badge-secondary:focus {
    outline-color: initial;
    box-shadow: rgba(89, 96, 100, 0.5) 0px 0px 0px 0.2rem;
}
.badge-success {
    color: rgb(234, 232, 229);
    background-color: rgb(32, 135, 56);
}
a.badge-success:focus,
a.badge-success:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(24, 102, 42);
}
a.badge-success.focus,
a.badge-success:focus {
    outline-color: initial;
    box-shadow: rgba(32, 135, 56, 0.5) 0px 0px 0px 0.2rem;
}
.badge-info {
    color: rgb(234, 232, 229);
    background-color: rgb(18, 131, 148);
}
a.badge-info:focus,
a.badge-info:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(14, 99, 112);
}
a.badge-info.focus,
a.badge-info:focus {
    outline-color: initial;
    box-shadow: rgba(18, 131, 148, 0.5) 0px 0px 0px 0.2rem;
}
.badge-warning {
    color: rgb(211, 207, 201);
    background-color: rgb(202, 152, 0);
}
a.badge-warning:focus,
a.badge-warning:hover {
    color: rgb(211, 207, 201);
    background-color: rgb(171, 127, 0);
}
a.badge-warning.focus,
a.badge-warning:focus {
    outline-color: initial;
    box-shadow: rgba(202, 152, 0, 0.5) 0px 0px 0px 0.2rem;
}
.badge-danger {
    color: rgb(234, 232, 229);
    background-color: rgb(167, 29, 42);
}
a.badge-danger:focus,
a.badge-danger:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(153, 26, 38);
}
a.badge-danger.focus,
a.badge-danger:focus {
    outline-color: initial;
    box-shadow: rgba(167, 29, 42, 0.5) 0px 0px 0px 0.2rem;
}
.badge-light {
    color: rgb(211, 207, 201);
    background-color: rgb(27, 30, 31);
}
a.badge-light:focus,
a.badge-light:hover {
    color: rgb(211, 207, 201);
    background-color: rgb(42, 45, 47);
}
a.badge-light.focus,
a.badge-light:focus {
    outline-color: initial;
    box-shadow: rgba(27, 30, 31, 0.5) 0px 0px 0px 0.2rem;
}
.badge-dark {
    color: rgb(234, 232, 229);
    background-color: rgb(44, 47, 49);
}
a.badge-dark:focus,
a.badge-dark:hover {
    color: rgb(234, 232, 229);
    background-color: rgb(24, 27, 28);
}
a.badge-dark.focus,
a.badge-dark:focus {
    outline-color: initial;
    box-shadow: rgba(44, 47, 49, 0.5) 0px 0px 0px 0.2rem;
}
.jumbotron {
    background-color: rgb(35, 38, 39);
}
.alert {
    border-color: transparent;
}
.alert-heading {
    color: inherit;
}
.alert-dismissible .close {
    color: inherit;
}
.alert-primary {
    color: rgb(125, 197, 255);
    background-color: rgb(38, 42, 43);
    border-color: rgb(0, 60, 124);
}
.alert-primary hr {
    border-top-color: rgb(0, 64, 132);
}
.alert-primary .alert-link {
    color: rgb(208, 204, 197);
}
.alert-secondary {
    color: rgb(196, 190, 182);
    background-color: rgb(40, 43, 44);
    border-color: rgb(60, 65, 67);
}
.alert-secondary hr {
    border-top-color: rgb(64, 69, 71);
}
.alert-secondary .alert-link {
    color: rgb(212, 208, 202);
}
.alert-success {
    color: rgb(155, 232, 173);
    background-color: rgb(26, 63, 41);
    border-color: rgb(37, 91, 51);
}
.alert-success hr {
    border-top-color: rgb(39, 97, 53);
}
.alert-success .alert-link {
    color: rgb(216, 212, 207);
}
.alert-info {
    color: rgb(143, 229, 243);
    background-color: rgb(20, 60, 68);
    border-color: rgb(30, 90, 98);
}
.alert-info hr {
    border-top-color: rgb(32, 94, 104);
}
.alert-info .alert-link {
    color: rgb(216, 212, 207);
}
.alert-warning {
    color: rgb(254, 217, 113);
    background-color: rgb(82, 63, 0);
    border-color: rgb(124, 93, 0);
}
.alert-warning hr {
    border-top-color: rgb(131, 99, 0);
}
.alert-warning .alert-link {
    color: rgb(207, 202, 196);
}
.alert-danger {
    color: rgb(227, 135, 144);
    background-color: rgb(68, 12, 17);
    border-color: rgb(105, 18, 27);
}
.alert-danger hr {
    border-top-color: rgb(111, 20, 29);
}
.alert-danger .alert-link {
    color: rgb(205, 201, 194);
}
.alert-light {
    color: rgb(155, 145, 132);
    background-color: rgb(25, 27, 28);
    border-color: rgb(34, 34, 70);
}
.alert-light hr {
    border-top-color: rgb(35, 35, 76);
}
.alert-light .alert-link {
    color: rgb(168, 161, 148);
}
.alert-dark {
    color: rgb(215, 211, 206);
    background-color: rgb(46, 51, 53);
    border-color: rgb(65, 70, 72);
}
.alert-dark hr {
    border-top-color: rgb(68, 74, 76);
}
.alert-dark .alert-link {
    color: rgb(231, 229, 226);
}
.progress {
    background-color: rgb(35, 38, 39);
}
.progress-bar {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
}
.progress-bar-striped {
    background-image: linear-gradient(45deg,
    rgba(24, 26, 27, 0.15) 25%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(24, 26, 27, 0.15) 50%,
    rgba(24, 26, 27, 0.15) 75%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0));
}
.list-group-item-action {
    color: rgb(183, 177, 168);
}
.list-group-item-action:focus,
.list-group-item-action:hover {
    color: rgb(183, 177, 168);
    text-decoration-color: initial;
    background-color: rgb(27, 30, 31);
}
.list-group-item-action:active {
    color: rgb(211, 207, 201);
    background-color: rgb(35, 38, 39);
}
.list-group-item {
    background-color: rgb(24, 26, 27);
    border-color: rgba(141, 131, 116, 0.13);
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: rgb(160, 152, 138);
    background-color: rgb(24, 26, 27);
}
.list-group-item.active {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 99, 206);
    border-color: rgb(0, 87, 181);
}
.list-group-item-primary {
    color: rgb(125, 197, 255);
    background-color: rgb(44, 48, 51);
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: rgb(125, 197, 255);
    background-color: rgb(52, 56, 58);
}
.list-group-item-primary.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 52, 107);
    border-color: rgb(0, 105, 217);
}
.list-group-item-secondary {
    color: rgb(196, 190, 182);
    background-color: rgb(46, 51, 52);
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: rgb(196, 190, 182);
    background-color: rgb(54, 59, 61);
}
.list-group-item-secondary.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(46, 49, 52);
    border-color: rgb(121, 112, 100);
}
.list-group-item-success {
    color: rgb(155, 232, 173);
    background-color: rgb(30, 73, 48);
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: rgb(155, 232, 173);
    background-color: rgb(34, 84, 55);
}
.list-group-item-success.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(17, 71, 29);
    border-color: rgb(43, 181, 75);
}
.list-group-item-info {
    color: rgb(143, 229, 243);
    background-color: rgb(24, 72, 79);
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: rgb(143, 229, 243);
    background-color: rgb(28, 82, 90);
}
.list-group-item-info.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(10, 68, 78);
    border-color: rgb(25, 175, 200);
}
.list-group-item-warning {
    color: rgb(254, 217, 113);
    background-color: rgb(93, 71, 0);
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: rgb(254, 217, 113);
    background-color: rgb(108, 82, 0);
}
.list-group-item-warning.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(107, 81, 3);
    border-color: rgb(210, 158, 6);
}
.list-group-item-danger {
    color: rgb(227, 135, 144);
    background-color: rgb(79, 14, 20);
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: rgb(227, 135, 144);
    background-color: rgb(92, 16, 24);
}
.list-group-item-danger.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(92, 22, 29);
    border-color: rgb(173, 42, 55);
}
.list-group-item-light {
    color: rgb(155, 145, 132);
    background-color: rgb(25, 27, 28);
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: rgb(155, 145, 132);
    background-color: rgb(32, 35, 36);
}
.list-group-item-light.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(96, 104, 108);
    border-color: rgb(84, 91, 95);
}
.list-group-item-dark {
    color: rgb(215, 211, 206);
    background-color: rgb(56, 61, 63);
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: rgb(215, 211, 206);
    background-color: rgb(63, 68, 71);
}
.list-group-item-dark.list-group-item-action.active {
    color: rgb(234, 232, 229);
    background-color: rgb(23, 24, 25);
    border-color: rgb(131, 122, 108);
}
.close {
    color: rgb(234, 232, 229);
    text-shadow: rgb(24, 26, 27) 0px 1px 0px;
}
.close:hover {
    color: rgb(234, 232, 229);
    text-decoration-color: initial;
}
button.close {
    background-color: transparent;
    border-color: initial;
}
.toast {
    background-color: rgba(24, 26, 27, 0.85);
    border-color: rgba(141, 131, 116, 0.1);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.25rem 0.75rem;
}
.toast-header {
    color: rgb(160, 152, 138);
    background-color: rgba(24, 26, 27, 0.85);
    border-bottom-color: rgba(141, 131, 116, 0.05);
}
.modal {
    outline-color: initial;
}
.modal-content {
    background-color: rgb(24, 26, 27);
    border-color: rgba(141, 131, 116, 0.2);
    outline-color: initial;
}
.modal-backdrop {
    background-color: rgb(0, 0, 0);
}
.modal-header {
    border-bottom-color: rgb(57, 62, 64);
}
.modal-footer {
    border-top-color: rgb(57, 62, 64);
}
.tooltip {
    text-decoration-color: initial; text-shadow: none;
}
.tooltip .arrow::before {
    border-color: transparent;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: rgb(141, 131, 116);
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: rgb(141, 131, 116);
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: rgb(141, 131, 116);
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: rgb(141, 131, 116);
}
.tooltip-inner {
    color: rgb(234, 232, 229);
    background-color: rgb(0, 0, 0);
}
.popover {
    text-decoration-color: initial;
    text-shadow: none;
    background-color: rgb(24, 26, 27);
    border-color: rgba(141, 131, 116, 0.2);
}
.popover .arrow::after, .popover .arrow::before {
    border-color: transparent;
}
.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
    border-top-color: rgba(141, 131, 116, 0.25);
}
.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
    border-top-color: rgb(48, 53, 55);
}
.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
    border-right-color: rgba(141, 131, 116, 0.25);
}
.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
    border-right-color: rgb(48, 53, 55);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
    border-bottom-color: rgba(141, 131, 116, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
    border-bottom-color: rgb(48, 53, 55);
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgb(51, 55, 58);
}
.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
    border-left-color: rgba(141, 131, 116, 0.25);
}
.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
    border-left-color: rgb(48, 53, 55);
}
.popover-header {
    background-color: rgb(29, 31, 32);
    border-bottom-color: rgb(55, 59, 61);
}
.popover-body {
    color: rgb(211, 207, 201);
}
.carousel-control-next,
.carousel-control-prev {
    color: rgb(234, 232, 229);
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: rgb(234, 232, 229);
    text-decoration-color: initial;
    outline-color: initial;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: initial;
    background-color: initial;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
    list-style-image: initial;
}
.carousel-indicators li {
    background-color: rgb(24, 26, 27);
    border-top-color: transparent;
    border-bottom-color: transparent;
}
.carousel-caption {
    color: rgb(234, 232, 229);
}
.spinner-border {
    border-color: currentcolor transparent currentcolor currentcolor;
}
.spinner-grow {
    background-color: currentcolor;
}
.bg-primary {
    background-color: rgb(0, 99, 206) !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: rgb(0, 79, 165) !important;
}
.bg-secondary {
    background-color: rgb(89, 96, 100) !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: rgb(70, 75, 78) !important;
}
.bg-success {
    background-color: rgb(32, 135, 56) !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: rgb(24, 102, 42) !important;
}
.bg-info {
    background-color: rgb(18, 131, 148) !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: rgb(14, 99, 112) !important;
}
.bg-warning {
    background-color: rgb(202, 152, 0) !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: rgb(171, 127, 0) !important;
}
.bg-danger {
    background-color: rgb(167, 29, 42) !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: rgb(153, 26, 38) !important;
}
.bg-light {
    background-color: rgb(27, 30, 31) !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: rgb(42, 45, 47) !important;
}
.bg-dark {
    background-color: rgb(44, 47, 49) !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: rgb(24, 27, 28) !important;
}
.bg-white {
    background-color: rgb(24, 26, 27) !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.border {
    border-color: rgb(57, 62, 64) !important;
}
.border-top {
    border-top-color: rgb(57, 62, 64) !important;
}
.border-right {
    border-right-color: rgb(57, 62, 64) !important;
}
.border-bottom {
    border-bottom-color: rgb(57, 62, 64) !important;
}
.border-left {
    border-left-color: rgb(57, 62, 64) !important;
}
.border-0 {
    border-color: initial !important;
}
.border-top-0 {
    border-top-color: initial !important;
}
.border-right-0 {
    border-right-color: initial !important;
}
.border-bottom-0 {
    border-bottom-color: initial !important;
}
.border-left-0 {
    border-left-color: initial !important;
}
.border-primary {
    border-color: rgb(0, 87, 181) !important;
}
.border-secondary {
    border-color: rgb(103, 95, 84) !important;
}
.border-success {
    border-color: rgb(37, 158, 65) !important;
}
.border-info {
    border-color: rgb(21, 153, 173) !important;
}
.border-warning {
    border-color: rgb(178, 133, 0) !important;
}
.border-danger {
    border-color: rgb(149, 26, 37) !important;
}
.border-light {
    border-color: rgb(51, 55, 57) !important;
}
.border-dark {
    border-color: rgb(122, 113, 100) !important;
}
.border-white {
    border-color: rgb(48, 53, 55) !important;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    border-color: initial;
}
.sr-only {
    border-color: initial;
}
.shadow-sm {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0.125rem 0.25rem !important;
}
.shadow {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem !important;
}
.shadow-lg {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1rem 3rem !important;
}
.shadow-none {
    box-shadow: none !important;
}
.stretched-link::after {
    background-color: rgba(0, 0, 0, 0);
}
.text-white {
    color: rgb(234, 232, 229) !important;
}
.text-primary {
    color: rgb(52, 164, 255) !important;
}
a.text-primary:focus,
a.text-primary:hover {
    color: rgb(0, 118, 214) !important;
}
.text-secondary {
    color: rgb(160, 152, 138) !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
    color: rgb(184, 178, 169) !important;
}
.text-success {
    color: rgb(98, 219, 125) !important;
}
a.text-success:focus,
a.text-success:hover {
    color: rgb(141, 230, 163) !important;
}
.text-info {
    color: rgb(82, 214, 235) !important;
}
a.text-info:focus,
a.text-info:hover {
    color: rgb(130, 225, 241) !important;
}
.text-warning {
    color: rgb(255, 201, 30) !important;
}
a.text-warning:focus,
a.text-warning:hover {
    color: rgb(255, 211, 75) !important;
}
.text-danger {
    color: rgb(225, 71, 86) !important;
}
a.text-danger:focus,
a.text-danger:hover {
    color: rgb(229, 96, 108) !important;
}
.text-light {
    color: rgb(230, 228, 224) !important;
}
a.text-light:focus,
a.text-light:hover {
    color: rgb(206, 201, 195) !important;
}
.text-dark {
    color: rgb(197, 192, 184) !important;
}
a.text-dark:focus,
a.text-dark:hover {
    color: rgb(221, 218, 214) !important;
}
.text-body {
    color: rgb(211, 207, 201) !important;
}
.text-muted {
    color: rgb(160, 152, 138) !important;
}
.text-black-50 {
    color: rgba(234, 232, 229, 0.5) !important;
}
.text-white-50 {
    color: rgba(234, 232, 229, 0.5) !important;
}
.text-hide {
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border-color: initial;
}
.text-decoration-none {
    text-decoration-color: initial !important;
}
.text-reset {
    color: inherit !important;
}
.MuiSvgIcon-root {
    fill: currentcolor;
}
.MuiSvgIcon-colorPrimary {
    color: rgb(110, 150, 206);
}
.MuiSvgIcon-colorSecondary {
    color: rgb(255, 33, 113);
}
.MuiSvgIcon-colorAction {
    color: rgba(234, 232, 229, 0.54);
}
.MuiSvgIcon-colorError {
    color: rgb(247, 79, 67);
}
.MuiSvgIcon-colorDisabled {
    color: rgba(234, 232, 229, 0.26);
}
.MuiPaper-root {
    color: rgba(234, 232, 229, 0.87);
    background-color: rgb(24, 26, 27);
}
.MuiPaper-outlined {
    border-color: rgba(141, 131, 116, 0.12);
}
.MuiPaper-elevation0 {
    box-shadow: none;
}
.MuiPaper-elevation1 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
.MuiPaper-elevation2 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
.MuiPaper-elevation3 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.MuiPaper-elevation4 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}
.MuiPaper-elevation5 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
}
.MuiPaper-elevation6 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
}
.MuiPaper-elevation7 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -2px,
    rgba(0, 0, 0, 0.14) 0px 7px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 2px 16px 1px;
}
.MuiPaper-elevation8 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}
.MuiPaper-elevation9 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px -3px,
    rgba(0, 0, 0, 0.14) 0px 9px 12px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 16px 2px;
}
.MuiPaper-elevation10 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 6px -3px,
    rgba(0, 0, 0, 0.14) 0px 10px 14px 1px,
    rgba(0, 0, 0, 0.12) 0px 4px 18px 3px;
}
.MuiPaper-elevation11 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 7px -4px,
    rgba(0, 0, 0, 0.14) 0px 11px 15px 1px,
    rgba(0, 0, 0, 0.12) 0px 4px 20px 3px;
}
.MuiPaper-elevation12 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px -4px,
    rgba(0, 0, 0, 0.14) 0px 12px 17px 2px,
    rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;
}
.MuiPaper-elevation13 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px -4px,
    rgba(0, 0, 0, 0.14) 0px 13px 19px 2px,
    rgba(0, 0, 0, 0.12) 0px 5px 24px 4px;
}
.MuiPaper-elevation14 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 9px -4px,
    rgba(0, 0, 0, 0.14) 0px 14px 21px 2px,
    rgba(0, 0, 0, 0.12) 0px 5px 26px 4px;
}
.MuiPaper-elevation15 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 9px -5px,
    rgba(0, 0, 0, 0.14) 0px 15px 22px 2px,
    rgba(0, 0, 0, 0.12) 0px 6px 28px 5px;
}
.MuiPaper-elevation16 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px,
    rgba(0, 0, 0, 0.14) 0px 16px 24px 2px,
    rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
}
.MuiPaper-elevation17 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 11px -5px,
    rgba(0, 0, 0, 0.14) 0px 17px 26px 2px,
    rgba(0, 0, 0, 0.12) 0px 6px 32px 5px;
}
.MuiPaper-elevation18 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 11px -5px,
    rgba(0, 0, 0, 0.14) 0px 18px 28px 2px,
    rgba(0, 0, 0, 0.12) 0px 7px 34px 6px;
}
.MuiPaper-elevation19 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 12px -6px,
    rgba(0, 0, 0, 0.14) 0px 19px 29px 2px,
    rgba(0, 0, 0, 0.12) 0px 7px 36px 6px;
}
.MuiPaper-elevation20 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 13px -6px,
    rgba(0, 0, 0, 0.14) 0px 20px 31px 3px,
    rgba(0, 0, 0, 0.12) 0px 8px 38px 7px;
}
.MuiPaper-elevation21 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 13px -6px,
    rgba(0, 0, 0, 0.14) 0px 21px 33px 3px,
    rgba(0, 0, 0, 0.12) 0px 8px 40px 7px;
}
.MuiPaper-elevation22 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 14px -6px,
    rgba(0, 0, 0, 0.14) 0px 22px 35px 3px,
    rgba(0, 0, 0, 0.12) 0px 8px 42px 7px;
}
.MuiPaper-elevation23 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 14px -7px,
    rgba(0, 0, 0, 0.14) 0px 23px 36px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 44px 8px;
}
.MuiPaper-elevation24 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}
.MuiTouchRipple-child {
    background-color: currentcolor;
}
.MuiButtonBase-root {
    color: inherit;
    border-color: initial;
    outline-color: initial;
    text-decoration-color: initial;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}
.MuiIconButton-root {
    color: rgba(234, 232, 229, 0.54);
}
.MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.MuiIconButton-root.Mui-disabled {
    color: rgba(234, 232, 229, 0.26);
    background-color: transparent;
}
@media (hover: none) {
    .MuiIconButton-root:hover {
        background-color: transparent;
    }
}
.MuiIconButton-colorInherit {
    color: inherit;
}
.MuiIconButton-colorPrimary {
    color: rgb(110, 150, 206);
}
.MuiIconButton-colorPrimary:hover {
    background-color: rgba(51, 66, 146, 0.04);
}
@media (hover: none) {
    .MuiIconButton-colorPrimary:hover {
        background-color: transparent;
    }
}
.MuiIconButton-colorSecondary {
    color: rgb(255, 33, 113);
}
.MuiIconButton-colorSecondary:hover {
    background-color: rgba(198, 0, 71, 0.04);
}
@media (hover: none) {
    .MuiIconButton-colorSecondary:hover {
        background-color: transparent;
    }
}
.MuiAppBar-colorDefault {
    color: rgba(234, 232, 229, 0.87);
    background-color: rgb(30, 32, 33);
}
.MuiAppBar-colorPrimary {
    color: rgb(234, 232, 229);
    background-color: rgb(51, 66, 146);
}
.MuiAppBar-colorSecondary {
    color: rgb(234, 232, 229);
    background-color: rgb(198, 0, 71);
}
.MuiAppBar-colorInherit {
    color: inherit;
}
.MuiAppBar-colorTransparent {
    color: inherit;
    background-color: transparent;
}
.MuiTypography-colorInherit {
    color: inherit;
}
.MuiTypography-colorPrimary {
    color: rgb(110, 150, 206);
}
.MuiTypography-colorSecondary {
    color: rgb(255, 33, 113);
}
.MuiTypography-colorTextPrimary {
    color: rgba(234, 232, 229, 0.87);
}
.MuiTypography-colorTextSecondary {
    color: rgba(234, 232, 229, 0.54);
}
.MuiTypography-colorError {
    color: rgb(247, 79, 67);
}
.MuiButton-root {
    color: rgba(234, 232, 229, 0.87);
}
.MuiButton-root:hover {
    text-decoration-color: initial;
    background-color: rgba(0, 0, 0, 0.04);
}
.MuiButton-root.Mui-disabled {
    color: rgba(234, 232, 229, 0.26);
}
@media (hover: none) {
    .MuiButton-root:hover {
        background-color: transparent;
    }
}
.MuiButton-root:hover.Mui-disabled {
    background-color: transparent;
}
.MuiButton-textPrimary {
    color: rgb(110, 150, 206);
}
.MuiButton-textPrimary:hover {
    background-color: rgba(51, 66, 146, 0.04);
}
@media (hover: none) {
    .MuiButton-textPrimary:hover {
        background-color: transparent;
    }
}
.MuiButton-textSecondary {
    color: rgb(255, 33, 113);
}
.MuiButton-textSecondary:hover {
    background-color: rgba(198, 0, 71, 0.04);
}
@media (hover: none) {
    .MuiButton-textSecondary:hover {
        background-color: transparent;
    }
}
.MuiButton-outlined {
    border-color: rgba(141, 131, 116, 0.23);
}
.MuiButton-outlined.Mui-disabled {
    border-color: rgba(141, 131, 116, 0.12);
}
.MuiButton-outlinedPrimary {
    color: rgb(110, 150, 206);
    border-color: rgba(47, 61, 136, 0.5);
}
.MuiButton-outlinedPrimary:hover {
    border-color: rgb(47, 61, 136);
    background-color: rgba(51, 66, 146, 0.04);
}
@media (hover: none) {
    .MuiButton-outlinedPrimary:hover {
        background-color: transparent;
    }
}
.MuiButton-outlinedSecondary {
    color: rgb(255, 33, 113);
    border-color: rgba(184, 0, 65, 0.5);
}
.MuiButton-outlinedSecondary:hover {
    border-color: rgb(184, 0, 65);
    background-color: rgba(198, 0, 71, 0.04);
}
.MuiButton-outlinedSecondary.Mui-disabled {
    border-color: rgba(141, 131, 116, 0.26);
}
@media (hover: none) {
    .MuiButton-outlinedSecondary:hover {
        background-color: transparent;
    }
}
.MuiButton-contained {
    color: rgba(234, 232, 229, 0.87);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    background-color: rgb(42, 45, 47);
}
.MuiButton-contained:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    background-color: rgb(48, 53, 55);
}
.MuiButton-contained.Mui-focusVisible {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.02) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.02) 0px 1px 18px 0px;
}
.MuiButton-contained:active {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}
.MuiButton-contained.Mui-disabled {
    color: rgba(234, 232, 229, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}
@media (hover: none) {
    .MuiButton-contained:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
        background-color: rgb(42, 45, 47);
    }
}
.MuiButton-contained:hover.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
}
.MuiButton-containedPrimary {
    color: rgb(234, 232, 229);
    background-color: rgb(51, 66, 146);
}
.MuiButton-containedPrimary:hover {
    background-color: rgb(38, 51, 128);
}
@media (hover: none) {
    .MuiButton-containedPrimary:hover {
        background-color: rgb(51, 66, 146);
    }
}
.MuiButton-containedSecondary {
    color: rgb(234, 232, 229);
    background-color: rgb(198, 0, 71);
}
.MuiButton-containedSecondary:hover {
    background-color: rgb(160, 14, 79);
}
@media (hover: none) {
    .MuiButton-containedSecondary:hover {
        background-color: rgb(198, 0, 71);
    }
}
.MuiButton-disableElevation {
    box-shadow: none;
}
.MuiButton-disableElevation:hover {
    box-shadow: none;
}
.MuiButton-disableElevation.Mui-focusVisible {
    box-shadow: none;
}
.MuiButton-disableElevation:active {
    box-shadow: none;
}
.MuiButton-disableElevation.Mui-disabled {
    box-shadow: none;
}
.MuiButton-colorInherit {
    color: inherit;
    border-color: currentcolor;
}
body {
    color: rgba(234, 232, 229, 0.87);
    background-color: rgb(27, 29, 30);
}
body::backdrop {
    background-color: rgb(27, 29, 30);
}
.MuiInputBase-root {
    color: rgba(234, 232, 229, 0.87);
}
.MuiInputBase-root.Mui-disabled {
    color: rgba(234, 232, 229, 0.38);
}
.MuiInputBase-input {
    color: currentcolor;
    border-color: #959595;
    background-image: none;
    background-color: initial;
    -webkit-tap-highlight-color: transparent;
}
.MuiInputBase-input::-webkit-input-placeholder {
    color: currentcolor;
}
.MuiInputBase-input:focus {
    outline-color: initial;
}
.MuiInputBase-input:invalid {
    box-shadow: none;
}
.MuiFormControl-root {
    border-color: initial;
}
.MuiFormLabel-root {
    color: rgba(234, 232, 229, 0.54);
}
.MuiFormLabel-root.Mui-focused {
    color: rgb(110, 150, 206);
}
.MuiFormLabel-root.Mui-disabled {
    color: rgba(234, 232, 229, 0.38);
}
.MuiFormLabel-root.Mui-error {
    color: rgb(247, 79, 67);
}
.MuiFormLabel-colorSecondary.Mui-focused {
    color: rgb(255, 33, 113);
}
.MuiFormLabel-asterisk.Mui-error {
    color: rgb(247, 79, 67);
}
.MuiInput-colorSecondary.MuiInput-underline::after {
    border-bottom-color: rgb(184, 0, 65);
}
.MuiInput-underline::after {
    border-bottom-color: rgb(47, 61, 136);
}
.MuiInput-underline.Mui-error::after {
    border-bottom-color: rgb(159, 19, 9);
}
.MuiInput-underline::before {
    border-bottom-color: rgba(141, 131, 116, 0.42);
}
.MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom-color: rgba(141, 131, 116, 0.87);
}
@media (hover: none) {
    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom-color: rgba(141, 131, 116, 0.42);
    }
}
.MuiLink-underlineNone {
    text-decoration-color: initial;
}
.MuiLink-underlineHover {
    text-decoration-color: initial;
}
.MuiLink-underlineHover:hover {
    text-decoration-color: initial;
}
.MuiLink-underlineAlways {
    text-decoration-color: initial;
}
.MuiLink-button {
    border-color: initial;
    outline-color: initial;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}
.MuiLink-button.Mui-focusVisible {
    outline-color: initial;
}
.MuiPopover-paper {
    outline-color: initial;
}
.MuiMenu-list {
    outline-color: initial;
}
.MuiSelect-select:focus {
    background-color: rgba(0, 0, 0, 0.05);
}
.MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) optgroup {
    background-color: rgb(24, 26, 27);
}
.MuiSelect-icon {
    color: rgba(234, 232, 229, 0.54);
}
.MuiSelect-icon.Mui-disabled {
    color: rgba(234, 232, 229, 0.26);
}
.MuiTable-root caption {
    color: rgba(234, 232, 229, 0.54);
}
.MuiTableCell-root {
    border-bottom-color: rgb(58, 62, 65);
}
.MuiTableCell-head {
    color: rgba(234, 232, 229, 0.87);
}
.MuiTableCell-body {
    color: rgba(234, 232, 229, 0.87);
}
.MuiTableCell-footer {
    color: rgba(234, 232, 229, 0.54);
}
.MuiTableCell-stickyHeader {
    background-color: rgb(27, 29, 30);
}
.MuiTableRow-root {
    color: inherit;
    outline-color: initial;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
    background-color: rgba(198, 0, 71, 0.08);
}

/* Override Style */
.vimvixen-hint {
    background-color: #7c5400 !important;
    border-color: #dab213 !important;
    color: #f5eaca !important;
}
::placeholder {
    opacity: 0.5 !important;
}

.primary-menu button.MuiButtonBase-root {
    background-color: rgb(0, 99, 206);
    color: rgb(255, 255, 255);
    padding: 1rem;
}

.primary-menu button.MuiButtonBase-root:disabled {
    background-color: rgb(0, 99, 206, 0.5);
}